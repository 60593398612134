<template>
  <div class="signup">
    <div class="signup__top mb-16 mb-tablet-32">
      <router-link to="/" class="signup__logo">
        <base-logo-vertical class="mobile" />
        <base-logo-horizontal class="tablet-desktop" />
      </router-link>
    </div>

    <div class="mb-16 mb-tablet-24 signup__steps-wrapper">
      <h1 class="heading color-primary mb-2">Tworzenie nowego konta</h1>
      <ul class="signup__steps">
        <li class="signup__step" :class="{ 'signup__step--active': step >= 1 }">
          Wprowadź dane
        </li>
        <li class="signup__step" :class="{ 'signup__step--active': step >= 2 }">
          Wybierz moduły
        </li>
        <li class="signup__step" :class="{ 'signup__step--active': step >= 3 }">
          Aktywuj konto!
        </li>
      </ul>
    </div>

    <main class="mb-24">
      <step-one
        v-if="step === 1"
        :data="userData"
        :loading="loading"
        :validationErrors="validationErrors"
        @next="stepOneNext"
      />
      <step-two
        v-else-if="step === 2"
        :modules="modules"
        :survey="survey"
        :toggleSurvey="toggleSurvey"
        :loading="loading"
        @next="stepTwoNext"
        @back="step = 1"
      />
      <step-three v-else-if="step === 3" :survey="survey" />
    </main>

    <page-footer class="signup__footer" />
  </div>
</template>

<script>
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import PageFooter from '@/components/PageFooter/PageFooter';

export default {
  components: {
    StepOne,
    StepTwo,
    StepThree,
    PageFooter,
  },
  metaInfo() {
    return {
      title: 'Załóż konto',
    };
  },
  data() {
    return {
      loading: false,
      step: 1,
      userData: {
        company: '',
        email: this.$route.query.email || '',
        phone: '',
        password: '',
        passwordRepeat: '',
      },
      modules: null,
      survey: false,
      validationErrors: {},
    };
  },
  methods: {
    toggleSurvey() {
      this.survey = !this.survey;
    },
    async stepOneNext() {
      if (this.userData.password !== this.userData.passwordRepeat) {
        return;
      }
      if (!this.modules) {
        this.loading = true;
        const { data } = await this.$api.get('v1/default_modules/');
        this.modules = data.results.map(module => ({
          id: module.pk,
          name: module.name_to_display,
          selected: false,
        }));
        this.loading = false;
      }
      this.step = 2;
    },
    async stepTwoNext() {
      this.loading = true;
      this.validationErrors = {};
      try {
        await this.$api.post(`auth/users/`, {
          email: this.userData.email,
          username: this.userData.email,
          password: this.userData.password,
          re_password: this.userData.passwordRepeat,
        });

        await this.$store.dispatch('user/login', {
          username: this.userData.email,
          password: this.userData.password,
          signup: true,
        });

        const userProfile = {
          custom_multi_query: this.modules
            .filter(module => module.selected)
            .map(module => module.id),
          custom_multi_query_reg: [],
          custom_multi_query_eu: [],
          custom_multi_query_nfz: [],
          poll_in_progress: this.survey,
        };
        await this.$store.dispatch('user/createProfile', userProfile);
        await this.$store.dispatch('user/updateProfile', userProfile);
        await this.$store.dispatch('user/getUser');

        const account = {
          company_name: this.userData.company,
          phone_number: this.userData.phone,
        };
        await this.$store.dispatch('user/updateAccount', account);
      } catch (err) {
        if (err.response) {
          this.validationErrors = Object.fromEntries(
            Object.keys(err.response.data).map(key => [key, true])
          );
          this.step = 1;
        }
        return;
      } finally {
        this.loading = false;
      }

      this.step = 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.signup {
  max-width: 75.42rem;
  margin: 0 auto;
  padding: 2rem 2rem 0;

  @include mq('desktop') {
    padding-top: 4rem;
  }
}
.signup__top {
  display: flex;
  justify-content: center;

  @include mq('tablet') {
    justify-content: flex-start;
  }
}
.signup__steps-wrapper {
  text-align: center;

  .heading {
    font-size: 1.64rem;
  }

  @include mq('tablet') {
    text-align: left;

    .heading {
      font-size: 2.5rem;
    }
  }
}
.signup__step {
  display: inline-block;
  color: $gray-4;
  font-size: 0.85rem;
  font-weight: bold;

  @include mq('tablet') {
    font-size: 2rem;
  }
  @include mq('desktop') {
    font-size: 2.5rem;
  }

  &::before {
    @include arrowPseudo($gray-4, 'right', 'small');
    display: inline-block;
    margin-left: 1rem;
    margin-right: 0.6rem;
    margin-bottom: 0.2em;

    @include mq('tablet') {
      @include arrowPseudo($gray-4, 'right');
    }
  }
  &:first-child::before {
    display: none;
  }
}
.signup__step--active {
  color: $primary;

  &::before {
    border-color: $primary;
  }

  &:last-child {
    color: $secondary;

    &::before {
      border-color: $secondary;
    }
  }
}
</style>
