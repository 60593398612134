<template>
  <div>
    <div class="row row--between row--align-top">
      <form
        @submit.prevent="$emit('next')"
        class="step1__form col-10 col-tablet-4 mb-24 text-center box-center"
        autocomplete="false"
      >
        <p class="step1__form-info">Wypełnij formularz:</p>

        <base-input v-model="data.company" name="company" class="mb-8">
          nazwa firmy
        </base-input>
        <base-input
          v-model="data.email"
          name="email"
          type="email"
          class="mb-8"
          required="true"
          :error="validationErrors.username || validationErrors.email"
        >
          adres e-mail
        </base-input>
        <base-input v-model="data.phone" name="phone" class="mb-8">
          numer telefonu
        </base-input>
        <base-input
          v-model="data.password"
          name="password"
          type="password"
          :error="validationErrors.password"
          class="mb-8"
          required="true"
          minlength="8"
        >
          hasło
        </base-input>
        <base-input
          v-model="data.passwordRepeat"
          name="passwordRepeat"
          type="password"
          :error="!passwordMatches || validationErrors.password"
          class="mb-2"
          required="true"
          minlength="8"
        >
          powtórz hasło
        </base-input>

        <p class="step1__required-notice" :class="hasErrors ? 'mb-4' : 'mb-12'">
          *pola obowiązkowe
        </p>

        <p v-if="validationErrors.email" class="color-error mb-12">
          Podaj poprawny adres e-mail!
        </p>
        <p v-if="validationErrors.username" class="color-error mb-12">
          E-mail już zarejestrowany!
        </p>
        <p v-if="validationErrors.password" class="color-error mb-12">
          Hasło zbyt krótkie lub słabe!
        </p>

        <base-button
          type="submit"
          theme="secondary"
          :loading="loading"
          class="step1__submit"
          >Dalej</base-button
        >
      </form>

      <div class="step1__info col-tablet-7 mb-16">
        <p class="step1__info-title mb-tablet-8 mb-16">
          Po założeniu konta otrzymasz:
        </p>
        <ul class="step1__info-list">
          <li class="step1__info-list-item mb-24 mb-tablet-12">
            <img src="./folder.svg" alt="" class="step1__info-list-icon" />
            <p>
              bezterminowy dostęp do<br />
              wyszukiwarki projektów
            </p>
          </li>
          <li class="step1__info-list-item">
            <img src="./graph.svg" alt="" class="step1__info-list-icon" />
            <p>
              14-dni darmowego<br />
              monitoringu obejmującego:
            </p>

            <ul class="step1__info-sublist">
              <li class="step1__info-sublist-item mb-8 mb-tablet-4">
                możliwość wyboru gotowych modułów branżowych;
              </li>
              <li class="step1__info-sublist-item mb-8 mb-tablet-4">
                możliwość stworzenia własnego modułu dostosowanego do Twoich
                potrzeb;
              </li>
              <li class="step1__info-sublist-item mb-8 mb-tablet-4">
                regularny mailing z informacjami<br />
                na temat projektów.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <p>
      Zakładając konto w aplikacji vigilex, przekazujesz nam swoje
      <strong>dane osobowe zawarte w&nbsp;formularzu rejestracyjnym</strong>.
      Będziemy je przetwarzać wraz
      <strong>z&nbsp;danymi potrzebnymi do zawarcia umowy</strong>, jeśli
      zdecydujesz się na uruchomienie płatnej usługi.
    </p>
    <p>
      Poniżej znajdziesz najważniejsze informacje dotyczące tego po co
      i&nbsp;jak je przetwarzamy oraz praw, które przysługują Ci w&nbsp;związku
      z&nbsp;tym.
    </p>
    <signup-legal />
  </div>
</template>

<script>
import SignupLegal from './SignupLegal';

export default {
  components: {
    SignupLegal,
  },
  props: {
    data: Object,
    loading: Boolean,
    validationErrors: Object,
  },
  computed: {
    passwordMatches() {
      return this.data.password === this.data.passwordRepeat;
    },
    hasErrors() {
      return Object.keys(this.validationErrors).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.step1 {
  display: flex;
}

.step1__form {
  margin-left: auto;
  margin-right: auto;

  @include mq('tablet') {
    margin-left: 0;
  }
}
.step1__form-info {
  margin-top: 0;
  font-size: 1.28rem;

  @include mq('tablet') {
    font-size: 1.42rem;
    text-align: left;
  }
}
.step1__required-notice {
  text-align: left;
  font-size: 0.85rem;
  color: $gray-3;
  margin-top: 0;
  margin-left: 1.1rem;
}
.step1__submit {
  width: 100%;
}

.step1__info {
  background: $gradient-diagonal;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding: 4rem 3rem;
  margin-top: 2rem;
  border-radius: 1.4rem;
  font-size: 1.14rem;
  color: #fff;

  @include mq('phone') {
    padding: 4rem;
  }
  @include mq('tablet') {
    margin-left: 0;
    margin-right: 0;
    padding: 2rem 4rem;
  }
}
.step1__info-title {
  font-size: 1.28rem;
  text-align: center;

  @include mq('tablet') {
    font-size: 1.42rem;
    margin-left: 6rem;
    text-align: left;
  }
}
.step1__info-list {
  font-weight: bold;
}
.step1__info-list-item {
  position: relative;
  text-align: center;

  p {
    padding-top: 4.5rem;
  }

  @include mq('tablet') {
    padding-left: 6rem;
    text-align: left;

    p {
      padding-top: 0;
    }
  }
}
.step1__info-list-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @include mq('tablet') {
    left: 0;
    transform: translateX(0);
  }
}
.step1__info-sublist {
  font-weight: normal;
  text-align: left;
  width: 90%;
  margin: 2rem auto 0;

  @include mq('tablet') {
    width: 100%;
    margin-top: 1.5rem;
  }
}
.step1__info-sublist-item {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1rem;

  &::before {
    @include arrowPseudo(#ffffff, 'right', 'medium');
    position: absolute;
    left: 0;
    top: 0.32rem;
    margin-bottom: 0.2em;
  }

  @include mq('tablet') {
    font-size: 1.14rem;
    padding-left: 2rem;

    &::before {
      @include arrowPseudo($primary, 'right');
    }
  }
}
</style>
