<template>
  <div class="mb-32">
    <h2 class="title mb-16 mb-tablet-8">
      <img class="title__icon mb-12 mb-tablet-0" src="./checklist.svg" alt="" />
      Wybierz obszary, które chcesz objać monitoringiem:
    </h2>
    <div class="row row--align-top row--center mb-12">
      <div class="col-10 col-tablet-7 mb-16">
        <div class="row">
          <div
            v-for="el in visibleModules"
            :key="el.id"
            class="col-12 col-tablet-6 mb-8"
          >
            <base-checkbox
              :value="el.selected"
              @input="el.selected = !el.selected"
              :name="el.name"
              full
              >{{ el.name }}</base-checkbox
            >
          </div>
        </div>

        <base-more
          v-if="hiddenModules.length"
          closed-text="pokaż więcej opcji"
          open-text="pokaż mniej opcji"
        >
          <div class="row">
            <div
              v-for="el in hiddenModules"
              :key="el.id"
              class="col-12 col-tablet-6 mb-8"
            >
              <base-checkbox
                :value="el.selected"
                @input="el.selected = !el.selected"
                :name="el.name"
                full
                >{{ el.name }}</base-checkbox
              >
            </div>
          </div>
        </base-more>
      </div>
      <div class="col-12 col-tablet-5">
        <div class="survey-info">
          <h3 class="title survey-title mb-4">
            <img
              class="title__icon mb-12 mb-tablet-0"
              src="./questionaire.svg"
              alt=""
            />
            Stwórz własny moduł monitoringu:
          </h3>
          <p>
            Wypełnij ankietę jeśli dostępne moduły nie odpowiadają Twoim potrzebom
            i chesz stworzyć własny.
          </p>
          <base-checkbox
            :value="survey"
            @input="toggleSurvey"
            name="survey"
            class="mb-4 survey-checkbox"
            full
            >Wypełnij ankietę</base-checkbox
          >
          <p>
            Na podstawie przesłanych odpowiedzi skonfigurujemy Twój monitoring.
          </p>
        </div>
      </div>
    </div>

    <div class="buttons mb-64 mb-tablet-0">
      <base-button
        theme="gray-3-plain buttons__first"
        @click="$emit('back')"
        uppercase
        >Wróć</base-button
      >
      <base-button
        theme="secondary"
        :loading="loading"
        @click="$emit('next')"
        class="buttons__second mb-12 mb-tablet-0"
        uppercase
        >Dalej</base-button
      >
    </div>
  </div>
</template>

<script>
const VISIBLE_COUNT = 12;

export default {
  props: {
    loading: Boolean,
    modules: Array,
    survey: Boolean,
    toggleSurvey: Function,
  },
  computed: {
    visibleModules() {
      return this.modules.slice(0, VISIBLE_COUNT);
    },
    hiddenModules() {
      return this.modules.slice(VISIBLE_COUNT);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.28rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include mq('tablet') {
    font-size: 1.42rem;
    flex-direction: row;
  }
}
.title__icon {
  margin-right: 0.8rem;
}
.survey-title {
  flex-direction: row;
  text-align: left;

  img {
    margin: 0 0.8rem 0 0;
  }
}
.survey-info {
  border: 2px solid $gray-5;
  border-radius: 0.57rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1.5rem 2.5rem;
  font-size: 1.14rem;
  text-align: center;

  @include mq('tablet') {
    margin-left: 0;
    margin-right: 0;
    padding: 3.8rem 4rem;
    text-align: left;
    margin-left: 1rem;
  }
}
.survey-checkbox {
  margin: 2rem 0 3rem 0;

  @include mq('tablet') {
    margin: 1rem 0;
  }
}

.buttons {
  padding: 0 3rem;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  @include mq('tablet') {
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.buttons__first {
  min-width: 10.5rem;

  @include mq('tablet') {
    margin-right: 1.8rem;
  }
}
.buttons__second {
  min-width: 18.2rem;
}
</style>
