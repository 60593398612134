<template>
  <div class="step-three">
    <p class="mb-24 step-three__paragraph">
      <span class="mb-4">Twoje konto zostało utworzone.</span><br /><br /> <strong>Wysłaliśmy Ci
      mailem link aktywacyjny.</strong> <br /><br />Jeśli nie aktywujesz konta w ciągu
      12 godzin, zostanie ono usunięte. <br />Aktywacja konta stanowi również warunek otrzymania
      zamówionych raportów i alertów.
    </p>

    <div class="step-three__buttons mb-32 mb-tablet-64">
      <base-button
        tag="router-link"
        to="/app/account/#modules"
        theme="secondary"
        class="step-three__button mb-4 mb-tablet-0"
        uppercase
        plain
        >Moje moduły</base-button
      >
      <base-button
        v-if="survey"
        tag="router-link"
        :to="{ name: 'survey', query: { fromSignup: true } }"
        theme="secondary"
        class="step-three__button mb-4 mb-tablet-0"
        uppercase
        plain
        >Wypełnij ankietę</base-button
      >
      <base-button
        tag="router-link"
        to="/app"
        theme="primary"
        class="step-three__button mb-4 mb-tablet-0"
        uppercase
        >Zobacz serwis</base-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    survey: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.step-three {
  max-width: 59rem;
  font-size: 1.42rem;
}
.step-three__paragraph {
  text-align: center;
  font-size: 1.28rem;

  span {
    display: block;
  }

  @include mq('tablet') {
    text-align: left;
    font-size: 1.42rem;

    span {
      display: initial;
    }
  }
}
.step-three__buttons {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;

  @include mq('tablet') {
    padding: 0;
    flex-direction: row;
  }
}
.step-three__button {
  flex: 1;

  @include mq('tablet') {
    margin-right: 1.5rem;
  }

  &:first-child {
    order: 2;
  }
  &:nth-child(2) {
    order: 3;
  }
  &:last-child {
    order: 1;
  }

  @include mq('tablet') {
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        order: $i;
      }
    }
  }
}
</style>
